import { Injectable } from '@angular/core';
import { WhiteLabeledDomain } from 'common/interfaces/models/white-labeled-domain';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface mediaUrl {
  logo?: string;
  invertedLogo?: string;
  icon?: string;
  invertedIcon?: string;
}

const defaultDomain: WhiteLabeledDomain & mediaUrl = {
  id: null,
  code: null,
  name: 'Picnic Tax',
};

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelingService {
  domain: WhiteLabeledDomain & mediaUrl = defaultDomain;

  constructor(private http: HttpClient) {}

  async init() {
    const { hostname } = window.location;
    const subDomains = hostname.split('.') || [];

    if (subDomains.length < 2) {
      return;
    }

    const savedDomain: WhiteLabeledDomain = JSON.parse(localStorage.getItem('domain')) || {};

    let domain = savedDomain;

    let subDomain = subDomains[0];

    if (subDomain.includes('staging-')) {
      subDomain = subDomain.split('staging-')[1];
    }

    if (!savedDomain || savedDomain.code !== subDomain) {
      const domains = await this.getDomains();

      domain = domains.find((domain) => domain.code === subDomain) || defaultDomain;

      if (!domain) {
        document.documentElement.classList.remove(domain.code);
        return;
      }

      localStorage.setItem('domain', JSON.stringify(domain));
    }

    const media = this.generateMediaUrls(domain.code);
    this.domain = { ...domain, ...media };

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = this.domain.icon;

    let title = document.querySelector('title');
    if (!title) {
      title = document.createElement('title');
    }
    title.textContent = this.domain.name;

    document.documentElement.classList.add(this.domain.code);
  }

  async getDomains() {
    return lastValueFrom(this.http.get<WhiteLabeledDomain[]>(`/api/domains`));
  }

  generateMediaUrls(domainCode: string): mediaUrl {
    if (!domainCode) {
      return {
        logo: null,
        invertedLogo: null,
        icon: null,
        invertedIcon: null,
      };
    }

    const s3Url = `https://static.picnictax.com/assets/${domainCode}`;

    return {
      logo: `${s3Url}.logo.svg`,
      invertedLogo: `${s3Url}.inverted.logo.svg`,
      icon: `${s3Url}.icon.svg`,
      invertedIcon: `${s3Url}.inverted.icon.svg`,
    };
  }
}
