import { Injectable } from '@angular/core';
import { TATARI_EVENT } from '../enums/tatari-event';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor() {}

  tatariIdentify(id: number) {
    // window.tatari should be defined in production only
    if ((window as any).tatari) {
      (window as any).tatari.identify(id);
    } else {
      console.log(`Tatari: identify user with id ${id}`);
    }
  }

  tatariTrack(event: TATARI_EVENT, id: number) {
    // window.tatari should be defined in production only
    if ((window as any).tatari) {
      // identify user before track if user is not identified yet
      if (!(window as any).tatari.userId) {
        this.tatariIdentify(id);
      }

      (window as any).tatari.track(event);
    } else {
      console.log(`Tatari: track event ${event} with user id ${id}`);
    }
  }

  redditTrack(action: 'Purchase' | 'SignUp') {
    // window.rdt should be defined in production only
    if ((window as any).rdt) {
      (window as any).rdt('track', action);
    } else {
      console.log(`Reddit: track action ${action}`);
    }
  }
}
