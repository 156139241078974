import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Capacitor, FilesystemDirectory, Plugins } from '@capacitor/core';
import { environment } from 'client/environments/environment';

const { SplashScreen, Filesystem, Share } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private platformService: Platform) { }

  isNative(): boolean { // Check whether the currently running platform is native (ios, android).
    return Capacitor.isNative;
  }

  platform(): string { // Get the name of the platform the app is currently running on: web, ios, android
    return Capacitor.getPlatform();
  }

  hideSplashScreen() {
    SplashScreen.hide();
  }

  openAppFallbackToStore() {
    const scheme = environment.mobileAppScheme;
    const appURL = `${scheme}://`;

    let storeURL: string;
    if (this.platformService.ANDROID) {
      storeURL = `https://play.google.com/store/apps/details?id=${environment.androidAppID}`;
    } else if (this.platformService.IOS) {
      storeURL = `https://apps.apple.com/us/app/picnic-tax/${environment.iosAppID}`;
    }

    if (!this.platformService.SAFARI) {
      window.location.href = appURL;
      setTimeout(() => {
        if (window.location.href !== appURL) {
          window.location.href = storeURL;
        }
      }, 50);
    }
  }

  share(blob: Blob, fileName: string) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      await Filesystem.writeFile({
        directory: FilesystemDirectory.Documents,
        path: fileName,
        recursive: true,
        data: (reader.result as string).split(',')[1]
      }).then(async () => {
        await Filesystem.getUri({
          directory: FilesystemDirectory.Documents,
          path: fileName
        }).then(async result => {
          await Share.share({
            dialogTitle: 'Share file',
            text: 'Share file',
            title: 'Share file',
            url: result.uri
          }).catch(err => {
            console.log('Error sharing!');
            console.log(err);
          });
        }).catch(err => {
          console.log('Error getting uri!');
          console.log(err);
        });
      }).catch(err => {
        console.log('Error writing!');
        console.log(err);
      });
    };
    reader.readAsDataURL(blob);
  }
}
