import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idle-warning-dialog',
  templateUrl: './idle-warning-dialog.component.html',
  styleUrls: ['./idle-warning-dialog.component.scss'],
})
export class IdleWarningDialogComponent implements OnInit, OnDestroy {
  remainingTime: number = 59;
  private intervalID: any;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { logout: () => void },
    private dialogRef: MatDialogRef<IdleWarningDialogComponent>
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.intervalID);
  }

  ngOnInit(): void {
    this.intervalID = setInterval(() => {
      if (this.remainingTime === 1) {
        this.logoutUser();
      } else {
        this.remainingTime -= 1;
      }
    }, 1000);
  }

  logoutUser() {
    this.data.logout();
    this.router.navigateByUrl('/');
    this.dialogRef.close();
  }

  continue() {
    this.dialogRef.close();
  }
}
