

export class Common {
  configs: any;
  constructor(configs: any) {
    this.configs = configs;
  }

  get environment() {
    return {
      users: `${this.configs.baseUrl}/users`,
      accProfileBase: 'https://d3m56lrbaa7h3i.cloudfront.net',
      hellosign: '030369aeafd18769c10b3e8f24e2da4e',
      mobileAppScheme: 'com.picnictax.app',
      androidAppID: 'com.picnictax.app',
      iosAppID: 'id1537283431'
    };
  }
}
