import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { User } from '../../../common/interfaces/models/user';
import { LoginCredentials } from '../../../common/interfaces/login-credentials';
import { AppService } from '../services/app.service';

import { TrackingService } from './tracking.service';
import { ROLE } from 'common/enums/role';
import { ResetPasswordBody } from 'common/interfaces/queries/reset-password-body';
import { MatDialog } from '@angular/material/dialog';
import { IdleWarningDialogComponent } from '../authentication/components/idle-warning-dialog/idle-warning-dialog.component';
import { LoginPayload } from '../interfaces/login-payload';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public loginSubject = new Subject<boolean>();
  private idleSubscription: Subscription;
  private loginPingIntervalId: number;
  isNativeApp = false;

  constructor(
    private http: HttpClient,
    private idle: Idle,
    private dialog: MatDialog,
    private trackingService: TrackingService,
    private appService: AppService
  ) {}

  initAuthenticationService() {
    this.isNativeApp = this.appService.isNative();
    if (this.isLoggedIn()) {
      if (!this.isNativeApp) {
        this.startIdleHandling();
      }

      this.startLoginPingHandling();
    }
  }

  async login(payload: LoginPayload, persistLogin: boolean): Promise<LoginCredentials> {
    const loginCredentials = await lastValueFrom(
      this.http.post<LoginCredentials>('/api/login', payload)
    );

    if (persistLogin) {
      localStorage.setItem('session', loginCredentials.token);
      localStorage.setItem('user', JSON.stringify(loginCredentials.user));
    } else {
      sessionStorage.setItem('session', loginCredentials.token);
      sessionStorage.setItem('user', JSON.stringify(loginCredentials.user));
    }

    this.loginSubject.next(true);
    if (!this.isNativeApp) {
      this.startIdleHandling();
    }
    await this.startLoginPingHandling();

    if (loginCredentials.user.role === ROLE.USER) {
      this.trackingService.tatariIdentify(loginCredentials.user.id);
    }

    return loginCredentials;
  }

  async loginB2b(payload: {
    email: string;
    domain: string;
    code: string;
  }): Promise<LoginCredentials> {
    const loginCredentials = await lastValueFrom(
      this.http.post<LoginCredentials>(`/api/login/b2b`, payload)
    );

    sessionStorage.setItem('session', loginCredentials.token);
    sessionStorage.setItem('user', JSON.stringify(loginCredentials.user));

    this.loginSubject.next(true);
    if (!this.isNativeApp) {
      this.startIdleHandling();
    }
    await this.startLoginPingHandling();

    return loginCredentials;
  }

  logout() {
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('user');

    sessionStorage.removeItem('pricing');
    sessionStorage.removeItem('incomeFilingYears');
    sessionStorage.removeItem('multipleStates');
    sessionStorage.removeItem('k1');
    sessionStorage.removeItem('brokerage');
    sessionStorage.removeItem('crypto');
    sessionStorage.removeItem('discount');
    sessionStorage.removeItem('allowClientOnboardingDocsPage');
    sessionStorage.removeItem('businessFilingType');
    sessionStorage.removeItem('filingId');

    localStorage.removeItem('session');
    localStorage.removeItem('user');

    this.loginSubject.next(false);
    this.idle.stop();
    clearInterval(this.loginPingIntervalId);

    if (!this.isNativeApp) {
      this.idleSubscription.unsubscribe();
    }
  }

  getLoginCredentials(): LoginCredentials {
    const token = sessionStorage.getItem('session') || localStorage.getItem('session');
    const savedUser = sessionStorage.getItem('user') || localStorage.getItem('user');

    if (token === null) {
      return null;
    }

    let user: User;
    try {
      user = JSON.parse(savedUser);
    } catch (err) {
      return null;
    }

    return { token, user };
  }

  async requestPasswordRecovery(email: string) {
    return lastValueFrom(this.http.get(`/api/auth/${email}/forgot-password`));
  }

  resetPassword(email: string, params: ResetPasswordBody) {
    return lastValueFrom(
      this.http.post<{ success: true }>(`/api/auth/${email}/reset-password`, params)
    );
  }

  isLoggedIn() {
    const token = sessionStorage.getItem('session') || localStorage.getItem('session');
    return token !== null;
  }

  async activateUser(email: string, activationCode: string, password?: string, firstName?: string) {
    let params: any = { activationCode };

    if (password) {
      params = { ...params, password };
    }

    if (firstName) {
      params = { ...params, firstName };
    }

    return lastValueFrom(this.http.post<{ success: true }>(`/api/auth/${email}/activate`, params));
  }

  async requestActivationEmailResend(email: string) {
    return lastValueFrom(this.http.get(`/api/auth/${email}/activate`));
  }

  private startIdleHandling() {
    this.idle.setIdle(60 * 14); // 14 min
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idleSubscription = this.idle.onIdleStart.subscribe(async () => {
      this.dialog.open(IdleWarningDialogComponent, {
        maxWidth: 400,
        data: {
          logout: () => {
            this.logout();
          },
        },
      });
    });

    this.idle.watch();
  }

  private async startLoginPingHandling() {
    this.loginPingIntervalId = window.setInterval(() => {
      lastValueFrom(this.http.post('/api/auth/ping', {}));
    }, 15000);
  }

  async requestUserIsLoggedIn(email: string) {
    return lastValueFrom(this.http.post<{ isLoggedIn: false }>(`/api/auth/${email}/loggedIn`, {}));
  }

  async requestB2bUserAccess(email: string, domain: string) {
    return lastValueFrom(
      this.http.post<{ success: true }>(`/api/auth/${email}/access`, { domain })
    );
  }
}
