import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'accountant/onboarding',
    loadChildren: () =>
      import('./accountant-onboarding/accountant-onboarding.module').then(
        (m) => m.AccountantOnboardingModule
      ),
  },
  {
    path: 'accountant',
    loadChildren: () => import('./accountant/accountant.module').then((m) => m.AccountantModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'filings',
    loadChildren: () => import('./filing/filing.module').then((m) => m.FilingModule),
  },
  {
    path: 'enterprise',
    loadChildren: () => import('./enterprise/enterprise.module').then((m) => m.EnterpriseModule),
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    loadChildren: () => import('./referrals/referrals.module').then((m) => m.ReferralsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
