import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'client/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  //TODO: Refactor methods into enum instead of hardcoded
  readonly openEndpoints = [
    { path: '/api/register/client', method: 'POST' },
    { path: '/api/register/accountant', method: 'POST' },
    { path: '/api/register/enterprise', method: 'POST' },
    { path: '/api/auth/:email/activate', method: '*' },
    { path: '/api/auth/:email/forgot-password', method: 'GET' },
    { path: '/api/auth/:email/reset-password', method: 'POST' },
    { path: '/api/auth/:email/access', method: 'POST' },
    { path: '/api/login', method: '*' },
    { path: '/api/login/b2b', method: 'POST' },
    { path: '/api/user-exist', method: '*' },
    { path: '/api/emails/contact', method: '*' },
    { path: '/api/discounts/:code/public', method: 'GET' },
    { path: '/api/employer-leads', method: 'POST' },
    { path: '/api/accountants-list', method: 'GET' },
    { path: '/api/accountants-list/:id', method: 'GET' },
    { path: '/api/price', method: '*' },
    { path: '/api/filings/surveys/:id', method: '*' },
    { path: '/api/domains', method: 'GET' },
    { path: '/api/auth/recaptcha', method: 'POST' },
  ];

  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // don't handle external urls
    if (request.url.startsWith('http://') || request.url.startsWith('https://')) {
      return next.handle(request);
    }

    const apiRequest = request.clone({ url: `${environment.baseUrl}${request.url}` });
    const urlSlugs = request.url.split('/');

    const openEndpoint = this.openEndpoints.find((endpoint) => {
      const endpointSlugs = endpoint.path.split('/');

      if (endpointSlugs.length !== urlSlugs.length) {
        return false;
      }

      for (const [index, slug] of urlSlugs.entries()) {
        if (endpointSlugs[index]?.startsWith(':')) {
          continue;
        }

        if (endpointSlugs[index] !== slug) {
          return false;
        }
      }

      return true;
    });

    if (
      openEndpoint &&
      (openEndpoint.method === '*' || openEndpoint.method === apiRequest.method)
    ) {
      //handle as open route
      return next.handle(apiRequest);
    } else {
      //handle as secure route
      const { token } = this.authService.getLoginCredentials();
      const authRequest = apiRequest.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
      return next.handle(authRequest);
    }
  }
}
