import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { AuthenticationService } from './services/authentication.service';
import { WhiteLabelingService } from './services/white-labeling.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private whiteLabelingService: WhiteLabelingService
  ) {}

  async ngOnInit() {
    await this.whiteLabelingService.init();
    this.authService.initAuthenticationService();

    if (Capacitor.isNative) {
      document.onclick = function (event: any): boolean | void {
        const element: any = event.target || event.srcElement;

        if (element.tagName === 'A' && element.target === '_blank' && element.href) {
          event.preventDefault();
          const url = element.href.replace('capacitor://localhost', 'https://www.picnictax.com');
          console.log(url);
          Capacitor.Plugins.Browser.open({ url });
          return true;
        }
      };
    }
  }
}
