const baseUrl = 'http://localhost:3000';

import { Common } from './environment.common';

const common = new Common({ baseUrl });

export const environment = {
  ...common.environment,
  baseUrl: 'https://staging-api.picnictax.com',
  production: false,
  gtmId: 'GTM-STGBADID',
  facebookId: 'INVALID_ID',
  stripe: {
    pubKey:
      'pk_test_51Dy15ADmHXBlU2H1gkf2WZSsGL2qlCeBI7cHZIqCDmkfQs1ZiB96JJE6WhNgij3nkWzAwtoqKouiPSWP9GmXK8cG00VlDHYhLr',
    clientId: 'ca_Fce1JYXCw9y7dSXWS5c2us3jZaOCJG78',
    redirectUri: 'https://staging.picnictax.com/accountant/onboarding/connect-success',
  },
  recaptcha: {
    v3Key: '6LdjHSYpAAAAAJwr3Z9tvi_Rj7Sc5HiGMka9vz1W',
    v2Key: '6LcbNJkpAAAAADrChJwgZyoPNfxjpO6GKjtm8aih',
  },
};
